import React from 'react';
import './style.css';

interface SpyItem {
  inView: boolean;
  element: HTMLElement;
}

interface ScrollspyProps {
  title?: string;
  ids: string[];
  offset: number;
  forceRefresh?: boolean;
}

interface ScrollspyState {
  items: SpyItem[];
}

const emptyState: ScrollspyState = {
  items: [],
};

const Scrollspy: React.FunctionComponent<ScrollspyProps> = ({
  title,
  offset,
  ids,
  forceRefresh,
}) => {
  const [state, setState] = React.useState(emptyState);

  const isInView = (element: HTMLElement) => {
    if (!element) {
      return false;
    }

    const rect = element.getBoundingClientRect();

    return rect.top >= 0 - offset && rect.bottom <= window.innerHeight + offset;
  };

  const lastItem = state.items[state.items.length - 1];
  const menuContainerClass =
    lastItem && lastItem.inView ? '' : 'fixed sidenavi';

  const spy = () => {
    const items = ids
      .map(id => {
        const element = document.getElementById(id);
        if (element) {
          return {
            inView: isInView(element),
            element,
          } as SpyItem;
        } else {
          return;
        }
      })
      .filter(item => item);

    const firstTrueItem = items.find(item => !!item && item.inView);

    if (!firstTrueItem) {
      return;
    } else {
      const update = items.map(item => {
        return { ...item, inView: item === firstTrueItem } as SpyItem;
      });

      setState({ items: update });
    }
  };

  const addScrollEvent = () => {
    spy();
    window.addEventListener('scroll', () => spy(), true);
  };

  React.useEffect(() => addScrollEvent, []);

  React.useEffect(() => {
    window.removeEventListener('scroll', () => spy(), true);
    addScrollEvent();
  }, [forceRefresh]);

  const scrollTo = (element: HTMLElement) => {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  };

  return (
    <div className={menuContainerClass}>
      {title && <h5 className="font-bold p-4">{title}</h5>}
      <ul
        className="overflow-y-scroll disable-scrollbars "
        style={{ height: '400px' }}
      >
        {state.items.map((item, k) => {
          return (
            <div className="flex items-center">
              <div className={`${item.inView ? "border-r-4 border-blue-600 rounded w-4 h-8" : ""}`}></div>
              <li
                className={`p-4 ${item.inView
                  ? 'font-bold text-black '
                  : ''
                  } hover:cursor-pointer`}
                key={k}
                onClick={() => {
                  scrollTo(item.element);
                  spy();
                }}
              >
                {item.element.innerText}
              </li>
            </div>
          );
        })}
      </ul>

    </div>
  );
};

Scrollspy.defaultProps = {
  ids: [],
  offset: 2,
  title: '',
};

export default Scrollspy;
