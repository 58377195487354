import React from "react";
import { useTranslation } from "react-i18next";
import "../i18n";
import Scrollspy from "../components/common/ScrollSpy";
import Footer from "../components/home/Footer";
import Header from "../components/home/Header";
import content from "../locales/termsAndPrivacy.en.json";
import arrowUp from "./arrowUp.png";
import md from "markdown-it";
import "@fortawesome/fontawesome-free/css/all.min.css";
import SEO from "../components/common/seo";

interface termsAndPrivacyState {
  currentIndex: number;
  forceRefresh: boolean;
}

type route = "terms" | "privacy";

const lastSegmentOfPathname =
  typeof window !== "undefined" &&
  (window.location.pathname.split("/")[
    window.location.pathname.split("/").length - 2
  ] as route);

const getRouteIndex = () => {
  switch (lastSegmentOfPathname) {
    case "terms":
      return 1;
    case "privacy":
      return 0;
    default:
      return 0;
  }
};

const emptyTermsAndPrivacyState: termsAndPrivacyState = {
  currentIndex: getRouteIndex(),
  forceRefresh: false,
};

const TermsAndCondition: React.FunctionComponent = () => {
  const { t } = useTranslation();

  const [state, setState] = React.useState(emptyTermsAndPrivacyState);

  // React.useEffect(() => {
  //   window.scrollTo({
  //     top: window.screenTop,
  //   });
  //   setState({
  //     forceRefresh: true,
  //     currentIndex: getRouteIndex(),
  //   });
  // }, [window.location.hash.substr(1)]);

  const generateId = (heading: string) =>
    heading.toLowerCase().split(" ").join("_");

  const downloadContent = content[state.currentIndex].downloadPath;

  return (
    <>
      <SEO
        props={{
          title: t("termsAndPrivacy.seoTitle"),
          path: lastSegmentOfPathname as route,
        }}
      />
      <Header />
      <div className="pt-40 text-center bg-gray-100">
        <div className="pb-24">
          <h1>{t("termsAndPrivacy.title.heading")}</h1>
          <p className="w-1/2 mx-auto p-2">
            {t("termsAndPrivacy.title.paragraph")}
          </p>
        </div>
      </div>
      <div className="flex container mx-auto">
        <div className="w-1/4 xl:ml-48  mt-24 pt-4 hidden lg:block">
          <Scrollspy
            ids={content[state.currentIndex].content.map((item) =>
              generateId(item.heading)
            )}
            offset={2}
            title={content[state.currentIndex].menuDisplay}
            forceRefresh={state.forceRefresh}
          />
        </div>
        <div className="lg:w-3/4 m-auto xl:mr-48 lg:mb-24 px-6 lg:px-0 ">
          <ul className="flex py-4 mb-8">
            {content.map((item, index) => (
              <li
                key={index}
                className={`py-4 px-1 mr-4 hover:cursor-pointer`}
                onClick={() =>
                  setState({ forceRefresh: true, currentIndex: index })
                }
              >
                {item.menuDisplay}
                <div
                  className={
                    index === state.currentIndex
                      ? "w-full border-t-4 border-blue-600 rounded h-2 mt-2"
                      : ""
                  }
                ></div>
              </li>
            ))}
          </ul>
          <h6 className="py-2">{content[state.currentIndex].heading}</h6>
          <div className="flex mb-8">
            <p className="pr-4 text-sm">
              {content[state.currentIndex].validity}
            </p>
            {downloadContent && downloadContent.trim() !== "" && (
              <p className="pl-4">
                <a href={content[state.currentIndex].downloadPath}>
                  {t("termsAndPrivacy.title.downloadText")}
                </a>
              </p>
            )}
          </div>
          <div>
            {content[state.currentIndex].content.map((item, i) => (
              <div key={i}>
                <h3 id={generateId(item.heading)}>{item.heading}</h3>
                {item.paragraphs.map((paragraph, index) => (
                  <div
                    key={index}
                    className="py-4"
                    dangerouslySetInnerHTML={{ __html: md().render(paragraph) }}
                  ></div>
                ))}
              </div>
            ))}
            <span className="float-right hover:cursor-pointer">
              <img
                width={24}
                height={24}
                alt="Go to Top"
                src={arrowUp}
                className="py-4"
                onClick={() =>
                  window.scrollTo({
                    top: window.screenTop,
                    behavior: "smooth",
                  })
                }
              />
            </span>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TermsAndCondition;
